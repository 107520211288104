<template>
  <div>
    <Header/>
    <div v-for="detail in details" :key="detail.index">
      <div class="container-fluid mt-4 px-0">
        <div class="ratio ratio-16x9">
          <iframe v-if="detail.Projectvideo" :src="detail.Projectvideo" class="frame-detail" frameborder="0"  width="100%" title="YouTube video" allowfullscreen></iframe>
          <img v-if="detail.project_img" :src="'https://makutacomm-api.makutapro.id/storage/images/projects/'+detail.project_img" width="100%" height="auto" alt="">
        </div>
      </div>
      <div class="container-fluid">
        <div class="container py-5">
          <div class="row ">
            <div class="col-12 col-md-6 col-lg-6 mb-3 mb-lg-0">
              <h3 style="color: #FE9800; font-weight: bold; ">{{detail.project_name}}</h3>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <h5 style="color: #FE9800; font-weight: bold; ">Client</h5>
              <hr style="border:1px solid #ccc;margin:0px;">
              <p class="mt-2">{{detail.project_name}}</p>

              <h5 style="color: #FE9800; font-weight: bold; ">Created</h5>
              <hr style="border:1px solid #ccc;margin:0px;">
              <p class="mt-2">{{detail.create_at}}</p>

              <h5 style="color: #FE9800; font-weight: bold; ">Role</h5>
              <hr style="border:1px solid #ccc;margin:0px;">
              <p class="mt-2">{{detail.role}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" >
      <div class="container">
        <div class="row my-3 my-lg-5">
          <div class="col-12 col-md-6 col-lg-6  mt-3 mt-lg-5" v-for="gallery in galleries" :key="gallery.index">
            <img :src="'https://makutacomm-api.makutapro.id/storage/images/projects/items/' + gallery.portfolio_img" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <FooterPage/>
  </div>
</template>

<script>
import Header from './Header.vue'
import FooterPage from './FooterPage.vue'
export default {
  components: { Header, FooterPage },
  name:'DetailWork',
  data(){
    return{
      details:[],
      galleries:[]
    }
  },
  props:['projectId'],
  mounted () {
    window.scrollTo(0, 0)
  },
  created(){
     this.$axios.get('https://makutacomm-adm.makutapro.id/api/detailOurWorks?projecttype_id=' + this.projectId)
      .then((response) => {
       console.log(response)
        this.details = response.data.data
       })

     this.$axios.get('https://makutacomm-adm.makutapro.id/api/detailOurWorksgalleri?projecttype_id=' + this.projectId)
      .then((response) => {
       console.log(response)
        this.galleries = response.data.data
       })
  }
}
</script>

<style scoped>
.frame-detail{
  height: 560px;
}
@media screen and (max-width: 568px) {
  .frame-detail{
    height: 200px;
  }
}

</style>